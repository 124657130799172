<template>
  <v-container>
      <v-snackbar v-model="password_changed" top color="success">Mot de passe modifié</v-snackbar>
    <v-snackbar v-model="need_name" color="red" top>Vous devez choisir un nom</v-snackbar>
    <v-snackbar v-model="name_changed" color="success" top>Votre nom a bien été modifié</v-snackbar>
    <v-snackbar v-model="notif_set" color="success" top>Vos choix de notification ont été enregistrés</v-snackbar>
    <v-snackbar v-model="account_created" color="success" top>Le compte a bien été créé</v-snackbar>


      <v-card class="mb-4">
          <v-card-title>Profil</v-card-title>
          <v-card-text>
              <div><v-text-field v-model="name" label="Ton nom"></v-text-field></div>
              <v-btn @click="changeName" outlined>Ajouter nom</v-btn>
              <v-switch v-model="mail_day" inset @change="changerNotification" label="Recevoir un mail pour chaque journée ajoutée"></v-switch>
              <v-switch v-model="mail_trip" inset @change="changerNotification" label="Recevoir un mail quand nous avons fini de visiter un pays"></v-switch>
          </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>Changer de mot de passe</v-card-title>
        <v-card-text>
            <v-form @submit="changePassword">
                <div> <v-text-field label="Précédent mot de passe" v-model="previous_password"></v-text-field> </div>
                <div> <v-text-field label="Nouveau mot de passe" v-model="new_password"></v-text-field> </div>
                <div> <v-text-field label="Confirmer mot de passe" :rules="same_password_rule" v-model="confirm_password"></v-text-field> </div>
                <v-btn type="submit" outlined>Confirmer</v-btn>
                </v-form>
        </v-card-text>
      </v-card>
      <v-card class="mt-4" v-if="admin">
        <v-card-title>Ajouter utilisateur</v-card-title>
        <v-card-text>
            <v-text-field label="Email" v-model="new_account_email"></v-text-field>
            <v-text-field label="Mot de passe" v-model="new_account_password"></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-btn outlined class="ml-2 mb-2" @click="createAccount">Créer compte</v-btn>
        </v-card-actions>
      </v-card>

      <v-btn class="mt-5 ml-3" @click="unlog" outlined>Me deconnecter</v-btn>
  </v-container>
</template>

<script>
import {auth} from '../main'
import {reauthenticateWithCredential,updatePassword,updateProfile, EmailAuthProvider, signOut} from 'firebase/auth'
import {getFunctions, httpsCallable} from 'firebase/functions';

export default {
    data(){
        return {
            name:"",
            previous_password:"",
            new_password:"",
            confirm_password:"",
            same_password_rule:[()=>{return this.new_password === this.confirm_password||"Les mots de passe ne sont pas identiques"}],
            password_changed:false,
            name_changed:false,
            notif_set:false,
            need_name:false,
            mail_day:false,
            mail_trip:false,
            new_account_email:"",
            new_account_password:"",
            account_created:false,
            admin:false
        }
    },
    methods:{
        async changePassword(){
            await reauthenticateWithCredential(auth.currentUser,EmailAuthProvider.credential(auth.currentUser.email,this.previous_password));
            updatePassword(auth.currentUser, this.new_password).then(()=>this.password_changed=true)
        },
        changeName(){
            updateProfile(auth.currentUser,{displayName:this.name}).then(()=>this.name_changed=true)
        },
        unlog(){
            signOut(auth);
            this.$router.push("/connexion");
        },
        async changerNotification() {
            const functions = getFunctions();
            const setNotif = httpsCallable(functions, 'set_notif');
            await setNotif({mail_day:this.mail_day,mail_trip:this.mail_trip});
            this.notif_set = true;
            auth.currentUser.getIdToken(true);
        },
        async createAccount() {
            const functions = getFunctions()
            const create = httpsCallable(functions,'create_account');
            console.log("create",this.new_account_email,this.new_account_password)
            console.log(await create({email:this.new_account_email,password:this.new_account_password}));
            this.account_created = true;
        }
    },
    async created(){
        this.name = auth.currentUser.displayName || "";
        if(this.name.length==0)
            this.need_name = true;
        let idToken = await auth.currentUser.getIdTokenResult();
        this.admin = !!idToken.claims.admin && idToken.claims.admin;
        this.mail_day = !!idToken.claims.mail_day && idToken.claims.mail_day;
        this.mail_trip = !!idToken.claims.mail_trip && idToken.claims.mail_trip;
    }
}
</script>

<style>

</style>