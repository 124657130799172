<template>
  <v-container>
    <v-card class="mb-3">
      <v-card-title>Se connecter</v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            label="email"
            v-model="email"
            :rules="email_rules"
          ></v-text-field>
          <v-text-field
            label="Mot de passe"
            type="password"
            v-model="password"
          ></v-text-field>
          <v-btn @click="connect" outlined>Se connecter</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Mot de passe oublié</v-card-title>
      <v-card-text>
        <v-text-field
          label="email"
          v-model="email_forgotten"
          :rules="email_rules"
        ></v-text-field>
        <v-btn @click="reset" outlined>Reinitialiser son mot de passe</v-btn>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snack_open"
      >Identifiant ou mot de passe incorrect</v-snackbar
    >
    <v-snackbar v-model="reset_open"
      >Email de réinitialisation du mot de passe envoyé</v-snackbar
    >
  </v-container>
</template>

<script>
import { auth } from "../main";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

export default {
  data() {
    return {
      email: "",
      password: "",
      email_forgotten: "",
      email_rules: [
        (v) => !!v || "E-mail requit",
        (v) =>
          /^[\w-.]+@[\w-]+\.[a-zA-Z]+(\.[a-zA-Z]+)?$/.test(v) ||
          "Adresse mail non valide",
      ],
      snack_open: false,
      reset_open: false,
    };
  },
  methods: {
    async connect() {
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password);
        this.$router.push("/");
      } catch (error) {
        this.snack_open = true;

      }
    },
    async reset() {
      try {
        await sendPasswordResetEmail(auth, this.email_forgotten);
        this.reset_open = true;
      } catch(error) {
          console.log(error)
      }
    },
  },
  
};
</script>
